import { Center, Link, Text } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { SiteURL } from "../../config";
interface Event {
  name: string;
  locationName: string;
  preposition?: string;
  date: string;
  link: string;
  latitude: number;
  longitude: number;
}
interface Location {
  ipAddress: string;
  latitude: number;
  longitude: number;
}
const PROXIMITY_THRESHOLD = 25; // miles

const fairs: Event[] = [{
  locationName: "Newton/Lexington",
  date: "Feb 3/4",
  name: "Summer Program Expo",
  link: "https://www.teensummerexpos.com",
  latitude: 42.3136457,
  longitude: -71.1862541
}, {
  locationName: "Newton/Lexington",
  date: "Feb 3/4",
  name: "Summer Program Expo",
  link: "https://www.teensummerexpos.com",
  latitude: 42.4430198,
  longitude: -71.2333331
}];
const getIpAddress = async (): Promise<string | undefined> => {
  try {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error(error);
    return;
  }
};
const geocodeIpAddress = async (ipAddress: string) => {
  try {
    const response = await fetch(`${SiteURL}/api/geoip?ip=${ipAddress}`);
    const data = await response.json();
    return {
      latitude: Number.parseFloat(data.latitude),
      longitude: Number.parseFloat(data.longitude)
    };
  } catch (error) {
    console.error(error);
    return;
  }
};
const getLocation = async () => {
  const ipAddress = await getIpAddress();
  if (!ipAddress) {
    return;
  }
  const coordinates = await geocodeIpAddress(ipAddress);
  if (!coordinates) {
    return;
  }
  return {
    ipAddress,
    ...coordinates
  };
};
const toRadians = (degrees: number) => {
  return degrees * Math.PI / 180;
};
const haversineDistance = (lat1: number, lon1: number, lat2: number, lon2: number) => {
  const R = 3958.8; // Radius of the Earth in miles
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distance in miles
};
const CalloutBanner = () => {
  const [location, setLocation] = useState<Location>();
  useEffect(() => {
    void getLocation().then(setLocation);
  }, []);
  const [closestFair] = useMemo(() => {
    if (!location) {
      return [undefined, Infinity];
    }
    let closestFair = undefined;
    let closestDistance = Infinity;
    for (const fair of fairs) {
      const distance = haversineDistance(location.latitude, location.longitude, fair.latitude, fair.longitude);
      if (distance < closestDistance) {
        closestFair = fair;
        closestDistance = distance;
      }
    }
    if (closestDistance <= PROXIMITY_THRESHOLD) {
      return [closestFair, closestDistance];
    }
    return [undefined, Infinity];
  }, [location]);
  const renderContent = () => {
    if (closestFair) {
      return <>
          <Text color="white" fontSize="inherit">
            Upcoming {closestFair.name} {closestFair.preposition ?? "in"}{" "}
            {closestFair.locationName} on {closestFair.date}
          </Text>
          <Link color="white" textDecoration="underline" href={closestFair.link} target="_blank">
            Free Registration
          </Link>
        </>;
    } else {
      return <>
          <Text color="white" fontSize="inherit">
            New: "Lists" functionality just added!
          </Text>
          <Text color="white" fontSize="inherit">
            Save & share your research.
          </Text>
        </>;
    }
  };
  return <Center role={"callout-banner"} bg="snow.blue-dark" padding={2} fontSize={{
    base: 12,
    lg: 16
  }} textAlign="center" display="flex" alignItems="center" flexDirection={{
    base: "column",
    lg: "row"
  }} gap={1} data-sentry-element="Center" data-sentry-component="CalloutBanner" data-sentry-source-file="CalloutBanner.tsx">
      {renderContent()}
    </Center>;
};
export default CalloutBanner;