"use client";

import * as Sentry from "@sentry/nextjs";
import { createContext, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { hotjar } from "react-hotjar";
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import { signOut as superTokensSignOut } from "supertokens-auth-react/recipe/session";
import Session, { useSessionContext } from "supertokens-auth-react/recipe/session";
import ThirdParty, { Google } from "supertokens-auth-react/recipe/thirdparty";
import { useGetCurrentUserQuery, User } from "../../../generated/graphql";
import graphqlRequestClient from "../../graphql/client";
import { SUPER_TOKENS_API_BASE_PATH, SUPER_TOKENS_API_DOMAIN, SUPER_TOKENS_API_GATEWAY_PATH, SUPER_TOKENS_APP_NAME, SUPER_TOKENS_WEBSITE_BASE_PATH, SUPER_TOKENS_WEBSITE_DOMAIN } from "@/config";
import { useAnalytics } from "@/features/analytics/components/AnalyticsProvider";
if (typeof window !== "undefined") {
  SuperTokens.init({
    appInfo: {
      appName: SUPER_TOKENS_APP_NAME,
      apiDomain: SUPER_TOKENS_API_DOMAIN,
      websiteDomain: SUPER_TOKENS_WEBSITE_DOMAIN,
      apiBasePath: SUPER_TOKENS_API_BASE_PATH,
      websiteBasePath: SUPER_TOKENS_WEBSITE_BASE_PATH,
      apiGatewayPath: SUPER_TOKENS_API_GATEWAY_PATH
    },
    recipeList: [ThirdParty.init({
      signInAndUpFeature: {
        providers: [Google.init()]
      }
    }), EmailPassword.init(), Session.init()],
    getRedirectionURL: context => {
      if (context.action === "SUCCESS" && context.newSessionCreated) {
        if (context.redirectToPath !== undefined) {
          return Promise.resolve(context.redirectToPath);
        }
        return Promise.resolve("/account/lists");
      }
      return Promise.resolve(undefined);
    },
    style: `
      [data-supertokens~=button] {
        background-color: rgb(98, 171, 234);
        border-color: rgb(98, 171, 234);
      }

      [data-supertokens~=superTokensBranding] {
        display: none;
      }

      [data-supertokens~="inputWrapper"]:focus-within {
        border: 1px solid rgb(98, 171, 234);
        box-shadow: 0 0 0 0.2rem rgba(98, 171, 234, 0.25);
      }

      [data-supertokens="checkedIcon"] path {
        fill: rgb(98, 171, 234);
      }

      [data-supertokens="spinnerIcon"] circle {
        stroke: rgb(98, 171, 234);
      }
    `
  });
}
export interface CurrentUserContextType {
  currentUser?: User;
  refetchCurrentUser?: () => Promise<unknown>;
  signOut: () => Promise<void>;
}
export const CurrentUserContext = createContext<CurrentUserContextType>({
  signOut: () => Promise.resolve()
});
export const CurrentUserProvider: React.FC<{
  children: React.ReactNode;
}> = ({
  children
}) => {
  const session = useSessionContext();
  const [currentUser, setCurrentUser] = useState<User>();
  const getCurrentUserQuery = useGetCurrentUserQuery(graphqlRequestClient);
  const analytics = useAnalytics();
  useEffect(() => {
    if (session.loading || getCurrentUserQuery.isLoading || !session.doesSessionExist || !getCurrentUserQuery.data?.currentUser) {
      setCurrentUser(undefined);
      return;
    }
    const user = getCurrentUserQuery.data.currentUser;
    signIn(user);
  }, [session, getCurrentUserQuery]);
  const signIn = (user: User) => {
    analytics.identify(user.identifier);
    analytics.setPeopleProperties({
      Type: user.type
    });
    setCurrentUser(user);
    Sentry.setUser({
      id: user.identifier,
      type: user.type
    });
    if (hotjar.initialized()) {
      hotjar.identify(user.identifier, {
        type: user.type
      });
    }
    if (ReactGA.isInitialized) {
      ReactGA.set({
        user_id: user.identifier,
        user_type: user.type
      });
    }
  };
  const signOut = async () => {
    setCurrentUser(undefined);
    Sentry.setUser(null);
    analytics.track("Sign out");
    analytics.reset();
    if (hotjar.initialized()) {
      hotjar.identify(null, {});
    }
    if (ReactGA.isInitialized) {
      ReactGA.set({
        user_id: null,
        user_type: null
      });
    }
    await superTokensSignOut();
  };
  return <CurrentUserContext.Provider value={{
    currentUser,
    refetchCurrentUser: getCurrentUserQuery.refetch,
    signOut
  }} data-sentry-element="unknown" data-sentry-component="CurrentUserProvider" data-sentry-source-file="AuthProvider.tsx">
      {children}
    </CurrentUserContext.Provider>;
};
export const AuthProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  return <SuperTokensWrapper data-sentry-element="SuperTokensWrapper" data-sentry-component="AuthProvider" data-sentry-source-file="AuthProvider.tsx">
      <CurrentUserProvider data-sentry-element="CurrentUserProvider" data-sentry-source-file="AuthProvider.tsx">{children}</CurrentUserProvider>
    </SuperTokensWrapper>;
};