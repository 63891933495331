import * as Sentry from "@sentry/nextjs";
import "@fontsource/nunito/400.css";
import "@fontsource/nunito/700.css";
import "@fontsource/nunito/800.css";
import { Hydrate } from "@tanstack/react-query";
import "large-small-dynamic-viewport-units-polyfill";
import type { AppProps } from "next/app";
import Head from "next/head";
import { appWithTranslation } from "next-i18next";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import { hotjar } from "react-hotjar";
import { GtmId, HotjarId, MIXPANEL_HOST, MIXPANEL_PROJECT_TOKEN, MIXPANEL_DEBUG } from "@/config";
import { AnalyticsProvider } from "@/features/analytics/components/AnalyticsProvider";
import { AppProviders } from "@/providers";
import { getLayout } from "@/ui/layouts/SnowdayLayout";
import "../styles/styles.css";
const App = ({
  Component,
  pageProps
}: AppProps) => {
  useEffect(() => {
    if (!HotjarId) {
      console.warn("No Hotjar ID provided");
      return;
    }
    console.info("Hotjar initialized with site ID", HotjarId);
    hotjar.initialize({
      id: HotjarId,
      sv: 6
    });
  }, []);
  useEffect(() => {
    if (!GtmId) {
      console.warn("No Google Tag Manager ID provided");
      return;
    }
    ReactGA.initialize(GtmId);
    console.info("Google Analytics initialized with Google Tag Manager ID", GtmId);
  });
  useEffect(() => {
    const errorListener = (event: ErrorEvent) => {
      Sentry.captureException(event.error);
    };
    const unhandledRejectionListener = (event: PromiseRejectionEvent) => {
      Sentry.captureException(event.reason);
    };
    window.addEventListener("error", errorListener);
    window.addEventListener("unhandledrejection", unhandledRejectionListener);
    return () => {
      window.removeEventListener("error", errorListener);
      window.removeEventListener("unhandledrejection", unhandledRejectionListener);
    };
  }, []);
  return <AnalyticsProvider projectToken={(MIXPANEL_PROJECT_TOKEN as string)} mixpanelHost={(MIXPANEL_HOST as string)} debug={MIXPANEL_DEBUG || process.env.NODE_ENV === "development"} data-sentry-element="AnalyticsProvider" data-sentry-component="App" data-sentry-source-file="_app.tsx">
      <AppProviders {...pageProps} data-sentry-element="AppProviders" data-sentry-source-file="_app.tsx">
        <Hydrate state={pageProps.dehydratedState} data-sentry-element="Hydrate" data-sentry-source-file="_app.tsx">
          <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
          </Head>
          {getLayout(<Component {...pageProps} />, pageProps)}
        </Hydrate>
      </AppProviders>
    </AnalyticsProvider>;
};
export default appWithTranslation(App);